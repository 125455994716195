import React from "react";
import dateformat from "dateformat";
import { downloadBlob } from "../../service/azure-storage-blob";
import { useState } from "react";

function Login() {
  const [email, setEmail] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const onSubmit = async (e) => {
    try {
      if (
        email.toLowerCase().includes("@suntecktransport.com") ||
        email.toLowerCase().includes("@suntecktransport.net") ||
        email.toLowerCase().includes("@suntecktts.com") ||
        email.toLowerCase().includes("@progressivetran.com") ||
        email.toLowerCase().includes("@modeglobal.com") ||
        email.toLowerCase().includes("@modetransportation.com")
      ) {
        const content = await downloadBlob(
          new Date().getFullYear() +
            "/" +
            month[new Date().getMonth()] +
            "/" +
            dateformat(
              new Date().toLocaleString("en-US", {
                timeZone: "CST",
              }),
              "mm-dd-yyyy"
            ) +
            "_logs.txt",
          email
        );

        setLoginErrorMessage(false);
        setIsLogin(true);
      } else {
        setLoginErrorMessage(true);
      }
    } catch (error) {
      console.log(error.toString());
    }
  };
  return (
    <>
      {!isLogin ? (
        <div className="container-fluid">
          <div className="row justify-content-center bg-green border-top">
            <div className="col-md-12">
              {/* <div
                className="modal"
                tabIndex={-1}
                style={{ display: "block !important" }}
              > */}
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      <img
                        src="Image/SUNTECKtts_Logo.jpeg"
                        className="logo"
                        alt="Sunteck Brand Logo"
                      />
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput1"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setLoginErrorMessage(false);
                        }}
                      />
                      <p className="helptext">
                        Please enter your work email address
                      </p>
                      <div className="errorMessage" hidden={!loginErrorMessage}>
                        Not a valid SunteckTTS Email Account.
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => onSubmit(e)}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/*Header ends here */}
        </div>
      ) : (
        <div className="container-fluid">
          {/*Header starts here */}
          <div className="row bg-green p-3">
            <a href="#">
              <img src="Image/Mode-logo.svg" alt="logo" />
            </a>
          </div>
          <div className="row justify-content-center bg-green border-top">
            <div className="col-md-9 py-5">
              <h1 className="color-white font-700 font-46">
                SUNTECKtts Migration Help Guides
              </h1>
            </div>
          </div>
          {/*Header ends here */}
          {/*Banner starts here*/}
          <div className="row banner-bg">
            <div className="col-md-12 col-lg-8 py-5">
              <div className="row flex-row py-5 ">
                <div className="col-md-auto alert-animation">
                  <img
                    className="ps-5 mt-4"
                    src="Image/alert-icon.svg"
                    alt="alert icon"
                  />
                </div>
                <div className="col-md-8">
                  <div className="w-100 float-start position-relative">
                    <span className="font-51 font-700 color-yellow">
                      Please complete your password reset by Sunday, 12/11 to
                      continue receiving email.
                    </span>
                    <span className="font-51 font-700 color-white alert-animation">
                      <img
                        className="element-position"
                        src="Image/banner-img-element.svg"
                        alt="Image element"
                      />
                    </span>
                  </div>
                  <p className="font-30 font-600 color-white mt-3 float-start alert-animation">
                    Not doing so will result in you not receiving your emails on
                    Monday 12/12. Exact instructions are in videos below.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-lg-4 d-flex align-items-center">
              <div className="d-md-none d-lg-block">
                <img
                  src="Image/Banner_Illustration.svg"
                  className="img-fluid"
                  alt="Banner Illustration"
                />
              </div>
            </div>
          </div>
          {/*Banner ends here*/}
          <div className="row justify-content-center bg-gray pt-5">
            <div className="col-md-9">
              {/*Notice section starts here*/}
              <div className="row mb-3 pt-3">
                <h2 className="font-35 font-700 mb-2">
                  SUNTECKtts Migration Notice
                </h2>
                {/* <p class="font-20 font-regular color-black mt-3 mb-5">
                MODE Global is committed to improving security, integration, and support for SUNTECKtts. As part of this commitment, we will be migrating 
                SUNTECKtts O365 (mailbox) environment to MODE Global O365 environment. We have been migrating (copying the data from source to destination) 
                Exchange, M365 groups, Teams, DLs, OneDrive and SharePoint in backend, and the migration is in its final stages now. MODE Global endeavors 
                to make this transition go as smoothly as possible.
              </p> */}
                <p className="font-20 font-500 color-black mt-3 mb-5">
                  We are transferring your mailboxes from one Office365 tenant
                  to another tenant without changing your email ID and branding.
                  On December 10th you need to perform the password reset and
                  Outlook re-configuration.{" "}
                  <span className="desc-bg alert-animation">
                    Refer to the videos and documents below in sequence
                  </span>
                </p>
              </div>
              {/*Notice section starts here*/}
              {/*Password reset section starts here*/}
              <div className="row mt-2">
                <div className="col-md-12">
                  <h2 className="font-30 font-700 my-1">Step 1 :</h2>
                </div>
                <div className="col-md-6 mb-4">
                  <h2 className="font-24 font-sm-22 font-700 my-3 title-width">
                    Password Reset for Email Account on Windows
                  </h2>
                  <video
                    className="video-width"
                    width={320}
                    height={240}
                    controls
                  >
                    <source
                      src="video/TemporaryPasswordReset_EmailAccountWindows.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <a
                    href="pdf/O365PasswordReset_ProcessDocument.pdf"
                    target="_blank"
                    className="font-16 font-400 text-decoration color-green doc-view"
                  >
                    <img className="me-2 pdf-icon" src="Image/pdf.svg" />
                    View Document
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <h2 className="font-24 font-sm-22 font-700 my-3 title-width">
                    Password Reset for Email Account on Mac
                  </h2>
                  <video
                    className="video-width"
                    width={320}
                    height={240}
                    controls
                  >
                    <source
                      src="video/TemporaryPasswordReset_EmailAccountMac.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <a
                    href="pdf/TemporaryPasswordReset_SunteckTTSEmailAccount_MAC.pdf"
                    target="_blank"
                    className="font-16 font-400 text-decoration color-green doc-view"
                  >
                    <img className="me-2 pdf-icon" src="Image/pdf.svg" />
                    View Document
                  </a>
                </div>
              </div>
              {/*Password reset section starts here*/}
              {/*Desktop Configuration section starts here*/}
              <div className="row my-2 mt-5">
                <div className="col-md-12">
                  <h2 className="font-30 font-700 my-1">Step 2 :</h2>
                </div>
                <div className="col-md-6 mb-4">
                  <h2 className="font-24 font-sm-22 font-700 my-3 title-width">
                    Email Account Setup in Outlook Desktop App on Windows
                  </h2>
                  <video
                    className="video-width"
                    width={320}
                    height={240}
                    controls
                  >
                    <source
                      src="video/EmailAccountSetup_OutlookDesktopAppWindows.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <a
                    href="pdf/SettingOutlookAccount_DesktopApp.pdf"
                    target="_blank"
                    className="font-16 font-400 text-decoration color-green doc-view"
                  >
                    <img className="me-2 pdf-icon" src="Image/pdf.svg" />
                    View Document
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <h2 className="font-24 font-sm-22 font-700 my-3 title-width">
                    Email Account Setup in Outlook Desktop App on Mac
                  </h2>
                  <video
                    className="video-width"
                    width={320}
                    height={240}
                    controls
                  >
                    <source
                      src="video/EmailAccountSetup_OutlookDesktopAppMAC.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <a
                    href="pdf/SettingSunteckTTSEmailAccount_MacApp.pdf"
                    target="_blank"
                    className="font-16 font-400 text-decoration color-green doc-view"
                  >
                    <img className="me-2 pdf-icon" src="Image/pdf.svg" />
                    View Document
                  </a>
                </div>
                <div className="col-md-12 mb-4 mt-4">
                  <h2 className="font-24 font-sm-22 font-700 my-3 title-width">
                    Please refer to the below video if you have issues in login
                    to Outlook app
                  </h2>
                  {/* <h2 className="font-24 font-sm-22 font-700 my-3 title-width">
                    Outlook Desktop Login issue on Windows
                  </h2> */}
                  <video
                    className="video-width"
                    width={320}
                    height={240}
                    controls
                  >
                    <source src="video/Outlook_Issue.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              {/*Desktop Configuration section starts here*/}
              {/*Mobile Configuration section starts here*/}
              <div className="row my-2 mt-5">
                <div className="col-md-12">
                  <h2 className="font-30 font-700 my-1">Step 3 :</h2>
                </div>
                <div className="col-md-6 mb-4">
                  <h2 className="font-24 font-sm-22 font-700 my-3 title-width">
                    Email Account Setup in Android Mobile App
                  </h2>
                  <video
                    className="video-width"
                    width={320}
                    height={240}
                    controls
                  >
                    <source
                      src="video/EmailAccountSetup_AndroidMobileApp.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <a
                    href="pdf/OutlookConfigurationsAndroid_ProcessDocument.pdf"
                    target="_blank"
                    className="font-16 font-400 text-decoration color-green doc-view"
                  >
                    <img className="me-2 pdf-icon" src="Image/pdf.svg" />
                    View Document
                  </a>
                </div>
                <div className="col-md-6 mb-4">
                  <h2 className="font-24 font-sm-22 font-700 my-3 title-width">
                    Email Account Setup in iPhone Mail App
                  </h2>
                  <video
                    className="video-width"
                    width={320}
                    height={240}
                    controls
                  >
                    <source
                      src="video/EmailAccountSetup_IOSMobileApp.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <a
                    href="pdf/EmailAccountSetUp_IOSMobileApp.pdf"
                    target="_blank"
                    className="font-16 font-400 text-decoration color-green doc-view"
                  >
                    <img className="me-2 pdf-icon" src="Image/pdf.svg" />
                    View Document
                  </a>
                </div>
              </div>
              {/*Mobile Configuration section starts here*/}
            </div>
          </div>
          {/*Footer starts here*/}
          <div className="row bg-img p-5 text-center justify-content-center mt-5">
            <h2 className="color-white font-600 font-30 col-md-8 mt-4">
              In case of any issues resetting your password or configuring
              Outlook, please feel free to reach us at,
            </h2>
            <a
              href="#"
              className="color-white bold font-700 font-35 col-md-8 mt-3 mb-4 link-pesudo-effect text-decoration-none"
            >
              +1 (206) - 803 - 2048
            </a>
            <p className="color-white bold font-35 font-600">Or</p>
            <a
              href="#"
              className="color-white font-700 font-35 col-md-8 mt-3 mb-4 text-decoration-none link-pesudo-effect"
            >
              it.support@modetransportation.com
            </a>
          </div>
          {/*Footer ends here*/}
        </div>
      )}
    </>
  );
}

export default Login;
