import dateformat from "dateformat";
import {
  BlobServiceClient,
  StorageSharedKeyCredential,
  ContainerClient,
} from "@azure/storage-blob";

// THIS IS SAMPLE CODE ONLY - DON'T STORE TOKEN IN PRODUCTION CODE
const sasToken =
  process.env.storagesastoken ||
  "sv=2021-06-08&ss=b&srt=sco&sp=rwdlaciytfx&se=2024-06-12T23:31:58Z&st=2022-11-08T16:31:58Z&spr=https,http&sig=ZFg%2Bwjw7Xd8d5ozHyFApcVUv9Ohpw8Y1dwtAGrPPaww%3D"; // Fill string with your SAS token
const containerName = `systemlogs`;
const storageAccountName =
  process.env.storageresourcename || "mgsunteckmigration"; // Fill string with your Storage resource name

const AZURE_STORAGE_CONNECTION_STRING =
  "DefaultEndpointsProtocol=https;AccountName=codegenstorage;AccountKey=55a+vkcWDaBidRrTfwDfTyOtNxZQiqhE4f2SM53l5f1cBh9BKNfeJ0KDXh0tvQwCrCce++oL0jPXwzwTNYrzWg==;EndpointSuffix=core.windows.net";

export const isStorageConfigured = () => {
  return !storageAccountName || !sasToken ? false : true;
};

const createBlobInContainer = async (containerClient, content, filename) => {
  const blobClient = containerClient.getBlockBlobClient(filename);

  const options = { blobHTTPHeaders: { blobContentType: "text" } };

  const uploadBlobResponse = await blobClient.upload(content, content.length);
  return filename;
};

const uploadFileToBlob = async (content, filename) => {
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  const containerClient = blobService.getContainerClient(containerName);
  console.log(containerClient);
  await createBlobInContainer(containerClient, content, filename);
  console.log(containerClient);

  return true;
};

// const account = "cloudocstr";
//   const sas =
//     "?sp=racwl&st=2022-11-04T18:37:57Z&se=2026-02-13T02:37:57Z&sv=2021-06-08&sr=c&sig=IWDWmnv3Cg56xeCwDEqREh%2BYAeRe7Qn2ycbynZ%2FvYHk%3D";
//   const containerName = "system-logs";

//   const blobServiceClient = new BlobServiceClient(
//     `https://${account}.blob.core.windows.net${sas}`
//   );

export const downloadBlob = async (filename, email) => {
  try {
    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobClient = containerClient.getBlobClient(filename);

    const isExists = await blobClient.exists();

    // console.log(isExists);

    if (isExists) {
      // Get blob content from position 0 to the end
      // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
      const downloadBlockBlobResponse = await blobClient.download();

      const downloaded = await blobToString(
        await downloadBlockBlobResponse.blobBody
      );

      // console.log(downloaded);
      await uploadFileToBlob(
        downloaded.toString() + "\n" + new Date() + "\t - \t" + email,
        filename
      );
    } else {
      await uploadFileToBlob(new Date() + "\t - \t" + email, filename);
    }
  } catch (error) {
    console.trace(error);
  }

  return;

  // [Browsers only] A helper method used to convert a browser Blob into string.
};

function blobToString(b) {
  var u, x;
  u = URL.createObjectURL(b);
  x = new XMLHttpRequest();
  x.open("GET", u, false); // although sync, you're not fetching over internet
  x.send();
  URL.revokeObjectURL(u);
  return x.responseText;
}

export default uploadFileToBlob;
